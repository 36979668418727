import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

let router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard'
    },
    {
      path: '/',
      component: () => import(/* webpackChunkName: "home" */ '../components/common/Home.vue'),
      meta: { title: '自述文件' },
      children: [
        {
          path: '/dashboard',
          component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/Dashboard.vue'),
          meta: { title: '系统首页' }
        },
        {
          path: '/BaseTable',
          component: () => import(/* webpackChunkName: "baseTable" */ '../components/page/BaseTable.vue'),
          meta: { title: '基础表格' }
        },
        {
          path: '/2048',
          component: () => import(/* webpackChunkName: "2048" */ '../components/page/2048.vue'),
          meta: { title: '2048' }
        },
        {
          path: '/catch-cat',
          component: () => import(/* webpackChunkName: "2048" */ '../components/page/catch-cat.vue'),
          meta: { title: 'catch-cat' }
        },
        // {
        //   path: '/product-table',
        //   component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table.vue'),
        //   meta: { title: '产品-基础数据模块' }
        // },
        // {
        //   path: '/product-table2',
        //   component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table2.vue'),
        //   meta: { title: '产品-付费数据模块' }
        // },
        {
          path: '/product-table3',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table3.vue'),
          meta: { title: '产品-日报模块' }
        },
        // {
        //   path: '/product-table4',
        //   component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table4.vue'),
        //   meta: { title: '产品-付费事件分析模块' }
        // },
        // {
        //   path: '/product-table5',
        //   component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table5.vue'),
        //   meta: { title: '产品-分批次模块' }
        // },
        {
          path: '/product-table6',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table6.vue'),
          meta: { title: '买量回本数据-安卓' }
        },
        {
          path: '/product-table15',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table15.vue'),
          meta: { title: '买量回本数据-iOS' }
        },
        {
          path: '/product-table7',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table7.vue'),
          meta: { title: '产品-项目模块' }
        },
        {
          path: '/product-project-data-starboom',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-project-data-starboom.vue'),
          meta: { title: '产品-Starboom项目模块' }
        },
        // {
        //   path: '/product-table8',
        //   component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table8.vue'),
        //   meta: { title: '产品-项目-AF模块' }
        // },
        {
          path: '/product-table9',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table9.vue'),
          meta: { title: '产品-群组分析模块' }
        },
        {
          path: '/product-table10',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table10.vue'),
          meta: { title: '产品-Admob数据模块' }
        },
        {
          path: '/product-table13',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table13.vue'),
          meta: { title: '产品-项目历史数据模块' }
        },
        {
          path: '/product-table14',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table14.vue'),
          meta: { title: '产品-活跃数据模块' }
        },
        {
          path: '/product-table-user',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table-user.vue'),
          meta: { title: '产品-用户行为路径模块' }
        },
        {
          path: '/product-table-FunnelAnalysis',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table-FunnelAnalysis.vue'),
          meta: { title: '产品-漏斗分析模块' }
        },
        {
          path: '/product-table-DataDemand',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table-DataDemand.vue'),
          meta: { title: '产品-数据需求模块' }
        },
        {
          path: '/product-table-UserCustom',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table-UserCustom.vue'),
          meta: { title: '产品-用户定制模块' }
        },
        {
          path: '/product-table-reviews',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/product-table-reviews.vue'),
          meta: { title: '产品-谷歌评论模块' }
        },
        {
          path: '/AdCash-AllReport',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/AdCash-AllReport.vue'),
          meta: { title: '变现-各广告平台数据模块' }
        },
        {
          path: '/iaa-basic',
          component: () => import(/* webpackChunkName: "productTable" */ '../components/page/iaa-basic.vue'),
          meta: { title: '变现-基础数据' }
        },
        {
          path: '/market-table1',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/market-table1.vue'),
          meta: { title: '市场-市场消耗信息' }
        },
        // {
        //   path: '/market-table2',
        //   component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/market-table2.vue'),
        //   meta: { title: '市场-分时统计' }
        // },
        {
          path: '/market-table3',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/market-table3.vue'),
          meta: { title: '市场-个人的消耗统计' }
        },
        // {
        //   path: '/market-table4',
        //   component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/market-table4.vue'),
        //   meta: { title: '市场-素材统计' }
        // },
        // {
        //   path: '/market-table5',
        //   component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/market-table5.vue'),
        //   meta: { title: '市场-用户查询' }
        // },
        // {
        //   path: '/market-table5a',
        //   component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/market-table5a.vue'),
        //   meta: { title: '市场-项目付费监控' }
        // },
        {
          path: '/market-ltvInfo',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/market-ltvInfo.vue'),
          meta: { title: '产品LTV数据' }
        },
        {
          path: '/market-lofInfo',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/market-lofInfo.vue'),
          meta: { title: '买量产品数据' }
        },
        {
          path: '/product-lofInfo',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/product-lofInfo.vue'),
          meta: { title: '买量产品数据' }
        },
        {
          path: '/market-table6',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/market-table6.vue'),
          meta: { title: '买量回本数据-安卓' }
        },
        {
          path: '/market-table15',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/market-table15.vue'),
          meta: { title: '买量回本数据-iOS' }
        },
        {
          path: '/MarketCampaign',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/MarketCampaign.vue'),
          meta: { title: '买量数据' }
        },
        {
          path: '/market-table7',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/market-table7.vue'),
          meta: { title: '市场-项目模块' }
        },
        {
          path: '/loongcheer-core',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/loongcheer-core.vue'),
          meta: { title: '财务-Loongcheer流水面板' }
        },
        {
          path: '/market-pixel',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/market-pixel.vue'),
          meta: { title: '市场-素材标记' }
        },
        {
          path: '/market-table_pixelCohort',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/market-table_pixelCohort.vue'),
          meta: { title: '市场-广告素材群组分析' }
        },
        {
          path: '/market-UAC2.5',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/market-uac2.5.vue'),
          meta: { title: '市场-UAC2.5' }
        },
        {
          path: '/data-table1',
          component: () => import(/* webpackChunkName: "dataTable" */ '../components/page/data-table1.vue'),
          meta: { title: '数据库-脚本模块' }
        },
        {
          path: '/user',
          component: () => import(/* webpackChunkName: "user" */ '../components/page/user.vue'),
          meta: { title: '用户管理' }
        },
        {
          path: '/user-group',
          component: () => import(/* webpackChunkName: "user-group" */ '../components/page/user-group.vue'),
          meta: { title: '用户组管理' }
        },
        {
          path: '/outsea-account',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/outsea-account.vue'),
          meta: { title: 'ADMAX户-消耗信息' }
        },
        {
          path: '/outsea-YFaccount',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/outsea-YFaccount.vue'),
          meta: { title: '预付账户消耗数据模块' }
        },
        {
          path: '/gm-main',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/gm-main.vue'),
          meta: { title: 'GM数据面板-总数据' }
        },
        {
          path: '/stickman3-main',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/stickman3-main.vue'),
          meta: { title: 'stickman3数据面板-总数据' }
        },
        {
          path: '/stickman3-server',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/stickman3-server.vue'),
          meta: { title: 'stickman3数据面板-区服数据' }
        },
        {
          path: '/stickman3-serverpay',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/stickman3-serverpay.vue'),
          meta: { title: 'stickman3数据面板-区服付费排行' }
        },
        {
          path: '/beyondwarrior-main',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/beyondwarrior-main.vue'),
          meta: { title: '武林闲侠数据面板-总数据' }
        },
        {
          path: '/beyondwarrior-event',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/beyondwarrior-event.vue'),
          meta: { title: '武林闲侠数据面板-事件数据' }
        },
        {
          path: '/beyondwarrior-event2',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/beyondwarrior-event.vue'),
          meta: { title: '武林闲侠数据面板-事件数据' }
        },
        {
          path: '/gm-server',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/gm-server.vue'),
          meta: { title: 'GM数据面板-区服数据' }
        },
        {
          path: '/gm-serverpay',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/gm-serverpay.vue'),
          meta: { title: 'GM数据面板-区服付费排行' }
        },
        {
          path: '/gm-item',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/gm-item.vue'),
          meta: { title: 'GM数据面板-道具流水' }
        },
        {
          path: '/gm-mail',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/gm-mail.vue'),
          meta: { title: 'GM数据面板-邮件查询' }
        },
        {
          path: '/gm-hero',
          component: () => import(/* webpackChunkName: "marketTable" */ '../components/page/gm-hero.vue'),
          meta: { title: 'GM数据面板-英雄养成' }
        },
        {
          path: '/tabs',
          component: () => import(/* webpackChunkName: "tabs" */ '../components/page/Tabs.vue'),
          meta: { title: '消息中心' }
        },
        {
          path: '/404',
          component: () => import(/* webpackChunkName: "404" */ '../components/page/404.vue'),
          meta: { title: '404' }
        },
        {
          path: '/403',
          component: () => import(/* webpackChunkName: "403" */ '../components/page/403.vue'),
          meta: { title: '403' }
        },
        {
          path: '/loongcheer-report',
          component: () => import(/* webpackChunkName: "loongcheerReport" */ '../components/page/loongcheer-report.vue'),
          meta: { title: '周报数据' }
        }
      ]
    },
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ '../components/page/Login.vue'),
      meta: { title: '登录' }
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
});

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Loongcheer`;
  const role = localStorage.getItem('ms_username');
  if (!role && to.path !== '/login') {
      next('/login');
  } else if (to.meta.permission) {
      // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
      role === 'admin' ? next() : next('/403');
  } else {
      // 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
      if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
          Vue.prototype.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
              confirmButtonText: '确定'
          });
      } else {
          next();
      }
  }
});

export default router